import * as React from "react"
import Layout from "../../components/layout"
import SingleMfnItemClient from "../../components/news/SingleMfnItemClient"
const NewsSSRPage = ({ serverData }) => (
  <Layout>
    <SingleMfnItemClient data={serverData} />
  </Layout>
)

export default NewsSSRPage

export async function getServerData(props) {
  const slug = props.params["slug"]

  try {
    const res = await fetch(`https://feed.mfn.se/v1/item/${slug}.json`)

    if (!res.ok) {
      throw new Error(`Response failed`)
    }

    return {
      props: await res.json(),
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    }
  }
}
