import React from "react"
import moment from "moment"

import Link from "../common/Link"
export default function SingleMfnItemClient({ data }) {
  // const [disclaimer, setDisclamer] = React.useState(true)
  // const [confirm, setConfirm] = React.useState(false)
  // const handleChange = () => {
  //   setDisclamer(!disclaimer)
  // }
  // const handleChange2 = () => {
  //   setConfirm(true)
  // }
  moment().format()
  const date = moment(data.content.publish_date).format("YYYY-MM-DD")
  const tags = data.properties.tags
  console.log(data)
  return (
    <div className="max-w-3xl mx-auto py-8 md:py-16 space-y-8 px-4 md:px-0">
      <div>
        {" "}
        <div>
          {" "}
          <header className="space-y-4">
            <h1 className="text-4xl text-brand font-bold text-primary">
              {data.content.title}
            </h1>
            <div className="block text-secondary"> {date}</div>
          </header>
          <article>
            {" "}
            <div
              dangerouslySetInnerHTML={{
                __html: data.content.html,
              }}
              className="space-y-4"
            />
            <div className="py-8">
              <p>Attatchments:</p>
              <p>
                {data.content.attachments.map((post, i) => {
                  return (
                    <p key={i}>
                      {" "}
                      <Link to={post.url}>{post.file_title}</Link>
                    </p>
                  )
                })}
              </p>
            </div>
          </article>
        </div>
      </div>
    </div>
  )
}
